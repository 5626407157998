:root {
  --base-dark: #002738;
  --base-medium-dark: #483c46;
  --base-medium-dark-alt: #4b2d1b;
  --base-medium: #04c9d1;
  --base-medium-alt: #26838c;
  --base-medium-light: #be7c4d;
  --base-medium-light-alt: #A08E6A;
  --base-light: #fbf4f4;
  --base-gray-dark: #495057;
  --base-gray-medium: #e9ecef;
  --base-modal-light: #f5f5f5;
  --base-modal-medium-light: #dff4f5;
  --base-modal-medium: #b1e5e7;
  --base-modal-medium-dark: #04c9d1;
  --base-modal-dark: #3e7375;
  --base-zebra-off: #f2f5f8;
  --base-zebra-on: #164b50;
  --header-margin-top: 47px;
  --base-box-shadow: rgba(29; 61; 124; 0.65);
  --base-status-processing: #8d99ae;
  --base-status-processing-background: rgba(141; 153; 174; 0.62);
  --base-status-info: #d7ebba;
  --base-status-info-background: rgba(215; 235; 186; 0.62);
  --base-status-error: #cea0ae;
  --base-status-error-background: rgba(206; 160; 174; 0.62);
  --base-neutral-light: rgba(0; 0; 0; 0.05);
  --base-warning: #cc9409;
  --base-error: #cc4b3d;
}